export const getGroups = async () => {
    const url = 'https://interview-it-dev-default-rtdb.firebaseio.com/groups.json';
    const response = await fetch(url);
    const data = await response.json();
    return data ?
        Object.keys(data).map(key => ({ id: key, ...data[key] }))
        : [];
}

export const postGroup = async group => {
    const url = 'https://interview-it-dev-default-rtdb.firebaseio.com/groups.json';
    const options = {
        method: 'POST',
        body: JSON.stringify(group)
    }
    const response = await fetch(url, options);
    const data = await response.json();
    return { id: data.name, ...group }
}

export const patchGroup = async group => {
    const url = `https://interview-it-dev-default-rtdb.firebaseio.com/groups/${group.id}.json`;
    const {id, ...body} = group;
    const options = {
        method: 'PATCH',
        body: JSON.stringify(body)
    }
    const response = await fetch(url, options);
    const data = await response.json();
    return { id: data.name, ...group }
}

export const deleteGroup = async id => {
    const url = `https://interview-it-dev-default-rtdb.firebaseio.com/groups/${id}.json`;
    const options = {
        method: 'DELETE'
    }
    const response = await fetch(url, options);
    return await response.json();
}