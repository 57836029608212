import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import HTMLParse from 'html-react-parser';
import React, { useState } from 'react';

const MyAccordion = ({ data, setId }) => {
    const [expanded, setExpanded] = useState();
    const handleChange = id => {
        if (expanded !== id) {
            setExpanded(id);
            setId(id)
        }
        else {
            setExpanded();
            setId();
        }
    }
    return (
        <Box>
            {data?.length ?
                data.map((item, i) => (
                    <Accordion key={item.title + i} expanded={expanded === item.id} onChange={() => handleChange(item.id)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Typography>{item.title}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {HTMLParse(item.answer)}
                        </AccordionDetails>
                    </Accordion>
                ))
                : null
            }
        </Box>
    );
};

export default MyAccordion;