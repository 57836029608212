import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, Stack, Tab, Tabs, TextField, Typography } from '@mui/material';
import { Container } from '@mui/system';
import React, { useEffect, useState } from 'react';
import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import MyAccordion from './components/MyAccordion';
import { deleteGroup, getGroups, patchGroup, postGroup } from './requests/group';
import { deleteQuestion, getQuestionsByGroup, patchQuestion, postQuestion } from './requests/question';

import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const App = () => {
  const [groups, setGroups] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [selectedGroupId, setSelectedGroupId] = useState();
  const [selectedQuestionId, setSelectedQuestionId] = useState();

  const [editQuestion, setEditQuestion] = useState();
  const [editGroup, setEditGroup] = useState();

  const [openGModal, setOpenGModal] = useState(false);
  const [openQModal, setOpenQModal] = useState(false);
  const [openDModal, setOpenDModal] = useState(false);

  const selectGroupId = (e, v) => setSelectedGroupId(v);

  const getEditorState = html => {
    if (html) {
      if (typeof html === 'object') return html;
      else {
        const contentBlock = htmlToDraft(html ?? '');
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
          return EditorState.createWithContent(contentState);
        } else return EditorState.createEmpty();
      }
    } else return EditorState.createEmpty();

  }

  const handleEditGroup = () => {
    if (editGroup) {
      if (editGroup.id) {
        patchGroup(editGroup)
          .then(data => {
            const newGroups = groups.map(g => g.id === editGroup.id ? data : g)
            setGroups(newGroups);
            setSelectedGroupId(newGroups[0]?.id);
          })
          .finally(() => setEditGroup())
      } else {
        postGroup(editGroup)
          .then(data => {
            const newGroups = [...groups, data]
            setGroups(newGroups);
            setSelectedGroupId(newGroups[0]?.id);
          })
          .finally(() => setEditGroup())
      }

    }
  }

  const handleDeleteGroup = () => {
    if (selectedGroupId) {
      deleteGroup(selectedGroupId)
        .then(() => {
          const newGroups = groups.filter(g => g.id !== selectedGroupId);
          setGroups(newGroups);
          setSelectedGroupId(newGroups[0]?.id);

        })
        .finally(() => setOpenDModal(false))
    }
  }

  const handleEditQuestion = () => {
    if (editQuestion) {
      if (editQuestion.id) {
        patchQuestion({ ...editQuestion, answer: draftToHtml(editQuestion?.answer ? convertToRaw(editQuestion.answer.getCurrentContent()) : '') })
          .then(data => {
            setQuestions(prev => prev.map(q => q.id === editQuestion.id ? data : q));
            setSelectedQuestionId(null);
          })
          .finally(() => setEditQuestion())
      } else {
        postQuestion({ ...editQuestion, answer: draftToHtml(editQuestion?.answer ? convertToRaw(editQuestion.answer.getCurrentContent()) : '') })
          .then(data => {
            setQuestions(prev => prev ? [...prev, data] : [data]);
            setSelectedQuestionId(null);
          })
          .finally(() => setEditQuestion())
      }
    }
  }

  const handleDeleteQuestion = () => {
    if (selectedQuestionId) {
      deleteQuestion(selectedQuestionId)
        .then(() => {
          setQuestions(prev => prev.filter(q => q.id !== selectedQuestionId));
          setSelectedQuestionId();
        })
        .finally(() => setOpenDModal(false))
    }
  }

  useEffect(() => {
    getGroups()
      .then(data => {
        setGroups(data);
        if (data?.length) setSelectedGroupId(data[0].id);
      })
  }, [])

  useEffect(() => {
    if (selectedGroupId) {
      getQuestionsByGroup(selectedGroupId)
        .then(data => {
          setQuestions(data)
        })
    }
  }, [selectedGroupId])

  useEffect(() => {
    if (editGroup) {
      setOpenGModal(true)
    } else if (openGModal) setOpenGModal(false);

    return () => setOpenGModal(false);
  }, [editGroup, openGModal])

  useEffect(() => {
    if (editQuestion) {
      setOpenQModal(true)
    } else if (openQModal) setOpenQModal(false);

    return () => setOpenQModal(false);
  }, [editQuestion, openQModal])


  return (
    <Container>

      <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Stack direction="row" spacing={2} margin={1}>
          <Button color="success" size="small" variant="contained"
            onClick={() => setEditGroup({ name: '' })}
          >
            <AddIcon fontSize='small' /> Group
          </Button>
          <Button color="primary" size="small" variant="contained"
            onClick={() => setEditGroup(groups.find(g => g.id === selectedGroupId))}
            disabled={!selectedGroupId}
          >
            <EditIcon fontSize='small' /> Group
          </Button>

          <Button color="error" size="small" variant="contained"
            onClick={() => setOpenDModal(true)}
            disabled={!selectedGroupId}
          >
            <DeleteIcon fontSize='small' /> Group
          </Button>
        </Stack>
        <Stack direction="row" spacing={2} margin={1}>
          <Button color="success" size="small" variant="contained"
            onClick={() => setEditQuestion({ title: '', answer: '', groupId: '' })}
            disabled={!groups?.length}
          >
            <AddIcon fontSize='small' /> Question
          </Button>
          <Button color="primary" size="small" variant="contained"
            onClick={() => setEditQuestion(questions.find(q => q.id === selectedQuestionId))}
            disabled={!selectedQuestionId}
          >
            <EditIcon fontSize='small' /> Question
          </Button>

          <Button color="error" size="small" variant="contained"
            onClick={() => setOpenDModal(true)}
            disabled={!selectedQuestionId}
          >
            <DeleteIcon fontSize='small' /> Question
          </Button>
        </Stack>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Tabs value={selectedGroupId} onChange={selectGroupId}>
          {
            groups?.length ?
              groups.map((group, i) => (
                <Tab label={group.name} value={group.id} key={group.name + i} />
              )) : null
          }
        </Tabs>
      </Box>
      <Box>
        <MyAccordion data={questions} setId={id => setSelectedQuestionId(id)} />
      </Box>

      <Dialog open={openGModal} onClose={() => setEditGroup()} fullWidth>
        <DialogTitle>{editGroup?.id ? 'Edit group' : 'Add new group'}</DialogTitle>
        <DialogContent>
          <Box sx={{ m: 1 }}>
            <FormControl fullWidth>
              <TextField
                label="Group name"
                size='small'
                fullWidth
                variant="outlined"
                value={editGroup?.name ?? ''}
                onChange={(e) => setEditGroup(prev => prev ? ({ ...prev, name: e.target.value }) : { name: e.target.value })}
              />
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditGroup()} color="info">Cancel</Button>
          <Button onClick={handleEditGroup} color="primary" variant="contained">{editGroup?.id ? 'Edit' : 'Add'}</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDModal} onClose={() => setOpenDModal(false)}>
        <DialogContent>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1 }}>
            <Typography marginRight={2}>
              Delete Questions? Are You Sure?
            </Typography>
            <Button variant="contained" color="error" onClick={handleDeleteQuestion} disabled={!selectedQuestionId}>Delete Question</Button>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1 }}>
            <Typography marginRight={2}>
              Delete Group with All Questions? Are You Sure?
            </Typography>
            <Button variant="contained" color="error" onClick={handleDeleteGroup} disabled={!selectGroupId}>Delete Group</Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDModal(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>



      <Dialog open={openQModal} onClose={() => setEditQuestion()} fullWidth>
        <DialogTitle>{editQuestion?.id ? 'Edit question' : 'Add new question'}</DialogTitle>
        <DialogContent>
          <Box sx={{ m: 1 }}>
            <FormControl fullWidth>
              <InputLabel id="select-group-label">Group</InputLabel>
              <Select
                labelId="select-group-laabel"
                value={editQuestion?.groupId ? groups.find(g => g.id === editQuestion.groupId) : ''}
                label="Select Group"
                onChange={(e) => setEditQuestion(prev => prev ? ({ ...prev, groupId: e.target.value?.id }) : { groupId: e.target.value?.id })}
              >
                <MenuItem value="">None</MenuItem>
                {
                  groups?.length ?
                    groups.map((group, i) => (
                      <MenuItem value={group} key={'select-group-' + i}>{group.name}</MenuItem>
                    ))
                    : null
                }
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ m: 1 }}>
            <FormControl fullWidth>
              <TextField
                label="Question"
                fullWidth
                variant="outlined"
                value={editQuestion?.title ? (editQuestion.title ?? '') : ''}
                onChange={(e) => setEditQuestion(prev => prev ? ({ ...prev, title: e.target.value }) : { title: e.target.value })}
              />
            </FormControl>
          </Box>

          <Box sx={{ m: 1 }}>
            <FormControl fullWidth>
              <Editor
                editorState={getEditorState(editQuestion?.answer)}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                onEditorStateChange={value => setEditQuestion(prev => prev ? ({ ...prev, answer: value }) : { answer: value })}
              />

              {/* <TextareaAutosize
                disabled
                placeholder="Answer"
                id="answer"
                label="Answer"
                variant="outlined"
                style={{ minHeight: 200 }}
                value={draftToHtml(editQuestion?.answer ? convertToRaw(editQuestion.answer.getCurrentContent()) : '')}
                // value={editQuestion?.answer ? (editQuestion.answer ?? '') : ''}
                // onChange={(e) => setEditQuestion(prev => prev ? ({ ...prev, answer: e.target.value }) : { answer: e.target.value })}
              /> */}
            </FormControl>
          </Box>

        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditQuestion()} color="info">Cancel</Button>
          <Button onClick={handleEditQuestion} color="primary" variant="contained">{editQuestion?.id ? 'Edit' : 'Add'}</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default App;