export const getQuestionsByGroup = async groupId => {
    const url = `https://interview-it-dev-default-rtdb.firebaseio.com/questions.json?orderBy="groupId"&equalTo="${groupId}"`;
    const response = await fetch(url);
    const data = await response.json();
    return data ? Object.keys(data).map(key => ({ id: key, ...data[key] })) : [];
}

export const postQuestion = async question => {
    const url = 'https://interview-it-dev-default-rtdb.firebaseio.com/questions.json';
    const options = {
        method: 'POST',
        body: JSON.stringify(question)
    }
    const response = await fetch(url, options);
    const data = await response.json();
    return { id: data.name, ...question };
}

export const patchQuestion = async question => {
    const url = `https://interview-it-dev-default-rtdb.firebaseio.com/questions/${question.id}.json`;
    const {id, ...body} = question;
    const options = {
        method: 'PATCH',
        body: JSON.stringify(body)
    }
    const response = await fetch(url, options);
    const data = await response.json();
    return { id: data.name, ...body };
}

export const deleteQuestion = async id => {
    const url = `https://interview-it-dev-default-rtdb.firebaseio.com/questions/${id}.json`;
    const options = {
        method: 'DELETE'
    }
    const response = await fetch(url, options);
    return await response.json();
}